var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-col',{attrs:{"cols":"12","xl":"11"}},[_c('b-card',{staticClass:"card border-0 hp-invoice-card",attrs:{"id":"invoice"}},[_c('b-row',{attrs:{"align-h":"between"}},[_c('b-col',{attrs:{"cols":"12","lg":"12"}},[_c('div',{staticClass:"col-lg-12 col-12"},[_c('div',{staticStyle:{"text-align":"center","justify-content":"center","align-item":"center","margin-top":"10px"},attrs:{"cols":"12"}},[_c('p',[_c('b',[_vm._v("Name: ")]),_vm._v(_vm._s(_vm.users.name)+" "+_vm._s(_vm.users.last_name)+" ")])])])])],1),_c('div',{staticClass:"divider"}),_c('b-row',[_c('b-col',{staticStyle:{"margin-top":"10px","margin-left":"5px","display":"flex"},attrs:{"cols":"12"}},[_c('b-col',{attrs:{"cols":"12","lg":"3"}},[_c('p',{style:({
              fontSize: _vm.textSize,
              marginLeft: _vm.marginLeft,
              marginTop: _vm.marginTop,
            })},[_c('b',[_vm._v("Start Date:")]),_vm._v(" "+_vm._s(_vm.users.start_date)+" ")])]),_c('b-col',[_c('p',{style:({
              fontSize: _vm.textSize,
              marginLeft: _vm.marginLeft,
              marginTop: _vm.marginTop,
            })},[_c('b',[_vm._v("End Date: ")]),_vm._v(" "+_vm._s(_vm.users.end_date)+" ")])]),_c('b-col')],1)],1),_c('div',{staticClass:"divider"}),_c('b-row',[_c('b-col',{staticStyle:{"margin-top":"10px","margin-left":"5px","display":"flex"},attrs:{"cols":"12"}},[_c('b-col',{attrs:{"cols":"12","lg":"3"}},[_c('p',{style:({
              fontSize: _vm.textSize,
              marginLeft: _vm.marginLeft,
              marginTop: _vm.marginTop,
            })},[_c('b',[_vm._v("Uber Earnings:")]),_vm._v(" "+_vm._s(_vm.users.uber_earning)+" ")])]),_c('b-col',{attrs:{"cols":"12","lg":"3"}},[_c('p',{style:({
              fontSize: _vm.textSize,
              marginLeft: _vm.marginLeft,
              marginTop: _vm.marginTop,
            })},[_c('b',[_vm._v("Bolt Earning:")]),_vm._v(" "+_vm._s(_vm.users.bolt_earning)+" ")])]),_c('b-col',[_c('p',{style:({
              fontSize: _vm.textSize,
              marginLeft: _vm.marginLeft,
              marginTop: _vm.marginTop,
            })},[_c('b',[_vm._v("Total:")]),_vm._v(" "+_vm._s(_vm.users.total)+" ")])])],1)],1),_c('div',{staticClass:"divider"}),_c('b-row',[_c('b-col',{staticStyle:{"margin-top":"10px","margin-left":"5px","display":"flex"},attrs:{"cols":"12"}},[_c('b-col',{attrs:{"cols":"12","lg":"3"}},[_c('p',{style:({
              fontSize: _vm.textSize,
              marginLeft: _vm.marginLeft,
              marginTop: _vm.marginTop,
            })},[_c('b',[_vm._v("moms_6%_tax:")]),_vm._v(" "+_vm._s(_vm.users.moms_6_tax)+" ")])]),_c('b-col',{attrs:{"cols":"12","lg":"3"}},[_c('p',{style:({
              fontSize: _vm.textSize,
              marginLeft: _vm.marginLeft,
              marginTop: _vm.marginTop,
            })},[_c('b',[_vm._v("5%_admin_fee:")]),_vm._v(" "+_vm._s(_vm.users.admin)+" ")])]),_c('b-col',[_c('p',{style:({
              fontSize: _vm.textSize,
              marginLeft: _vm.marginLeft,
              marginTop: _vm.marginTop,
            })},[_c('b',[_vm._v("moms_25_tax:")]),_vm._v(" "+_vm._s(_vm.users.moms_25_tax)+" ")])])],1)],1),_c('div',{staticClass:"divider"}),_c('b-row',[_c('b-col',{staticStyle:{"margin-top":"10px","margin-left":"5px","display":"flex"},attrs:{"cols":"12"}},[_c('b-col',{attrs:{"cols":"12","lg":"3"}},[_c('p',{style:({
              fontSize: _vm.textSize,
              marginLeft: _vm.marginLeft,
              marginTop: _vm.marginTop,
            })},[_c('b',[_vm._v("Net Total:")]),_vm._v(" "+_vm._s(_vm.users.net_total)+" ")])]),_c('b-col',[_c('p',{style:({
              fontSize: _vm.textSize,
              marginLeft: _vm.marginLeft,
              marginTop: _vm.marginTop,
            })},[_c('b',[_vm._v("Net Payable:")]),_vm._v(" "+_vm._s(_vm.users.net_payable)+" ")])]),_c('b-col')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <b-col cols="12" xl="11">
    <b-card id="invoice" class="card border-0 hp-invoice-card">
      <b-row align-h="between">
        <b-col cols="12" lg="12">
          <div class="col-lg-12 col-12">
            <div cols="12" style=" text-align: center;
            justify-content: center;
            align-item: center; margin-top: 10px">
              <p
              >
                <b>Name: </b>{{ users.name }} {{ users.last_name }}
              </p>
            </div>
          </div>
        </b-col>
     
      </b-row>
     
      <div class="divider"></div>
       <b-row>
        <b-col
          cols="12"
          style="
            margin-top: 10px;
            margin-left: 5px;
            display: flex;
          "
        >
          <b-col cols="12" lg="3">
            <p
              :style="{
                fontSize: textSize,
                marginLeft: marginLeft,
                marginTop: marginTop,
              }"
            >
              <b>Start Date:</b> {{ users.start_date }}
            </p>
          </b-col>
          <b-col>
            <p
              :style="{
                fontSize: textSize,
                marginLeft: marginLeft,
                marginTop: marginTop,
              }"
            >
              <b>End Date: </b> {{ users.end_date}}
            </p>
          </b-col>
           <b-col>
           </b-col>
        </b-col>
      </b-row>
       <div class="divider"></div>
      <b-row>
        <b-col
          cols="12"
          style="
            margin-top: 10px;
            margin-left: 5px;
            display: flex;
          "
        >
          <b-col cols="12" lg="3">
            <p
              :style="{
                fontSize: textSize,
                marginLeft: marginLeft,
                marginTop: marginTop,
              }"
            >
              <b>Uber Earnings:</b> {{ users.uber_earning }}
            </p>
          </b-col >
          <b-col cols="12" lg="3">
            <p
              :style="{
                fontSize: textSize,
                marginLeft: marginLeft,
                marginTop: marginTop,
              }"
            >
              <b>Bolt Earning:</b> {{ users.bolt_earning }}
            </p>
          </b-col>
          <b-col>
            <p
              :style="{
                fontSize: textSize,
                marginLeft: marginLeft,
                marginTop: marginTop,
              }"
            >
              <b>Total:</b> {{ users.total }}
            </p>
          </b-col>
        </b-col>
      </b-row>
      <div class="divider"></div>
      <b-row>
        <b-col
          cols="12"
          style="
            margin-top: 10px;
            margin-left: 5px;
            display: flex;
          "
        >
          <b-col cols="12" lg="3">
            <p
              :style="{
                fontSize: textSize,
                marginLeft: marginLeft,
                marginTop: marginTop,
              }"
            >
              <b>moms_6%_tax:</b> {{ users.moms_6_tax }}
            </p>
          </b-col>
          <b-col cols="12" lg="3">
            <p
              :style="{
                fontSize: textSize,
                marginLeft: marginLeft,
                marginTop: marginTop,
              }"
            >
              <b>5%_admin_fee:</b> {{ users.admin }}
            </p>
          </b-col>
          <b-col >
            <p
              :style="{
                fontSize: textSize,
                marginLeft: marginLeft,
                marginTop: marginTop,
              }"
            >
              <b>moms_25_tax:</b> {{ users.moms_25_tax }}
            </p>
          </b-col>
          
        </b-col>
      </b-row>
      <div class="divider"></div>
      <b-row>
        <b-col
          cols="12"
          style="
            margin-top: 10px;
            margin-left: 5px;
            display: flex;
          "
        >
          <b-col cols="12" lg="3">
            <p
              :style="{
                fontSize: textSize,
                marginLeft: marginLeft,
                marginTop: marginTop,
              }"
            >
              <b>Net Total:</b> {{ users.net_total }}
            </p>
          </b-col>
          <b-col>
            <p
              :style="{
                fontSize: textSize,
                marginLeft: marginLeft,
                marginTop: marginTop,
              }"
            >
              <b>Net Payable:</b> {{ users.net_payable }}
            </p>
          </b-col>
           <b-col>
           </b-col>
        </b-col>
      </b-row>
    </b-card>
  </b-col>
</template>
            
<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BTable,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BInputGroupAppend,
  BSpinner,
} from "bootstrap-vue";
import axios from "axios";
import Papa from "papaparse";

// new code
// import code from "./code";
// new code end
export default {
  data() {
    return {
      perPage: 8,
      currentPage: 1,
      sortBy: "age",
      sortDesc: false,
      selectedCardOption: "",
      rowToUpdate: null,
      users: [], // Instead of 'items', use 'users' array to store fetched data
      fields: [
        { key: "id", sortable: true },
        { key: "Driver_name", sortable: true },
        { key: "start_date", sortable: true },
        { key: "end_date", sortable: true },
        { key: "uber_earning", sortable: true },
        { key: "bolt_earning", sortable: true },
        // { key: "total", sortable: true },
        // { key: "moms_6%_tax", sortable: true },
        // { key: "total_net", sortable: true },
        // { key: "5%_admin_fee", sortable: true },
        // { key: "net_payable", sortable: true },
        // { key: "moms_25_tax", sortable: true },
        { key: "total_net_payable", sortable: true },
        // { key: "actions", label: "Actions" },
      ],

      filter: "", // Define filter property for search functionality
      totalRows: 0, // Initialize totalRows to 0
      showDeleteConfirmation: false,
      itemIdToDelete: null,
      loading: false,
      startDateFilter: "",
      endDateFilter: "",
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BTable,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BFormSelect,
    BPagination,
    BInputGroupAppend,
    BSpinner,
  },
  computed: {
    filteredUsers() {
      const filteredUsers = this.users.filter((user) => {
        const startDate = new Date(user.start_date);
        const endDate = new Date(user.end_date);
        const filterStartDate = this.startDateFilter
          ? new Date(this.startDateFilter)
          : null;
        const filterEndDate = this.endDateFilter
          ? new Date(this.endDateFilter)
          : null;
        if (filterStartDate && startDate < filterStartDate) {
          return false;
        }

        if (filterEndDate && endDate > filterEndDate) {
          return false;
        }

        return true;
      });

      return filteredUsers;
    },

    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    rows() {
      return this.users.length;
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true; // Set loading to true before fetching data
      const userId = this.$route.params.id;
      let apiUrl = userId ? `driverUberID/${userId}` : "uberdata";
      axios
        .get(apiUrl) // Replace 'your_api_endpoint_url_here' with your actual API URL
        .then((response) => {
          this.users = response.data.data;

          this.totalRows = this.users.length;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          this.loading = false; // Set loading to false after fetching data, whether success or error
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    codeClick() {
      this.codeActive = !this.codeActive;
    },
  },
};
</script>